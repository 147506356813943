import React, { useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [active, setActive] = useState(true);
  const project = [
    {
      title: "Sattva Exotica",
      image: "pic4.jpg",
    },
    {
      title: "Shubh Greens",
      image: "shubh.jpeg",
    },
    {
      title: "Tata Projects – Anant University",
      image: "pic3.jpg",
    },
    {
      title: "Sanskardham",
      image: "pic4.jpg",
    },
    {
      title: "Ozone Tuscany",
      image: "pic5.jpg",
    },
    {
      title: "Iscon LIG Housing",
      image: "pic6.jpg",
    },
    {
      title: "SPG Evans",
      image: "pic8.jpg",
    },
    {
      title: "Sepal Elegant",
      image: "pic9.jpg",
    },
    {
      title: "Sattva Exotica",
      image: "pic2.jpg",
    },
    {
      title: "Hirabaug",
      image: "pic3.jpg",
    },
    {
      title: "Private Bungalow at Khoraj",
      image: "pic4.jpg",
    },
  ];

  const toggle = () => {
    setActive(!active);
  };
  return (
    <div className="page-wraper">
      <div className="page-content">
        <div className="landingpage">
          <ul>
            <li>
            <h2 className="text-white">We Build Your Dream</h2>
              <p>
              With a strong sense of space planning, we build exclusive and unique real estate projects catering to the need of customers.
              </p>
            </li>
          </ul>
        </div>
        {/* meet & ask */}
        <div className="section-full z-index100 meet-ask-outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 meet-ask-row p-tb30">
                <div className="row d-flex">
                  <div className="col-lg-6">
                    <div className="icon-bx-wraper clearfix left">
                      <div className="icon-xl ">
                        {" "}
                        <span className=" icon-cell text-white">
                          <i className="flaticon-buildings" />
                        </span>{" "}
                      </div>
                      <div className="icon-content text-white">
                        <h3 className="dez-tilte text-uppercase m-b10">
                          Meet &amp; Ask
                        </h3>
                        <p>
                          Constructing a modern website requires modern tools.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 m-t20">
                    <Link
                      to="/contact"
                      className="site-button-secondry button-skew m-l10"
                    >
                      <span>Contact Us</span>
                      <i className="fa fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-full content-inner overlay-secondary-dark text-white bg-img-fix"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(images/background/bg18.jpg)",
          }}
        >
          <div className="container px-5">
            <h2>Our Mission</h2>
            <blockquote>Empowering the new age of sustainable living, our mission at Aranath Group is to pioneer innovative developments that harmonize modern living with environmental stewardship. Committed to creating vibrant, sustainable communities, we strive to redefine urban living for a brighter future.</blockquote>
          </div>
        </div>

        <div className="section-full content-inner ">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="text-uppercase">Latest blog post</h2>
              <div className="dez-separator-outer ">
                <div className="dez-separator bg-secondry style-skew" />
              </div>
            </div>
            <div className="section-content ">
              <div className="blog-carousel owl-carousel owl-none">
                <div className="item">
                  <div className="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dez-post-media dez-img-effect zoom-slow">
                      {" "}
                      <Link to="/trends-in-urban-living" target="_blank">
                        <img src="images/blog/latest-blog/pic1.jpg" alt="" />
                      </Link>{" "}
                    </div>
                    <div className="dez-post-info p-t10">
                      <div className="dez-post-title ">
                        <h5 className="post-title">
                          <Link to="/trends-in-urban-living" target="_blank">
                          Trends in Urban Living: The Future of Real Estate
                          </Link>
                        </h5>
                      </div>
                      <div className="dez-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <i className="fa fa-calendar" />
                            10 Aug
                            <span> 2016</span>{" "}
                          </li>
                          <li className="post-author">
                            <i className="fa fa-user" />
                            By <Link to="javascript:void(0);">Admin</Link>{" "}
                          </li>
                          <li className="post-comment">
                            <i className="fa fa-comments" />{" "}
                            <Link to="javascript:void(0);">0 comment</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dez-post-text">
                        <p>
                        Urban living is undergoing a profound transformation, driven by changing demographics, technological advancements, and evolving lifestyles. According to the United Nations, over 68% of the world's population is projected to live in urban areas by 2050, emphasizing the importance of sustainable urban development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dez-post-media dez-img-effect zoom-slow">
                      {" "}
                      <Link to="/sustainable-development" target="_blank">
                        <img src="images/blog/latest-blog/pic2.jpg" alt="" />
                      </Link>{" "}
                    </div>
                    <div className="dez-post-info p-t10">
                      <div className="dez-post-title ">
                        <h5 className="post-title">
                          <Link to="/sustainable-development" target="_blank">
                          Sustainable Development: Building for a Greener Tomorrow
                          </Link>
                        </h5>
                      </div>
                      <div className="dez-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <i className="fa fa-calendar" />
                            10 Aug
                            <span> 2016</span>{" "}
                          </li>
                          <li className="post-author">
                            <i className="fa fa-user" />
                            By <Link to="javascript:void(0);">Admin</Link>{" "}
                          </li>
                          <li className="post-comment">
                            <i className="fa fa-comments" />{" "}
                            <Link to="javascript:void(0);">0 comment</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dez-post-text">
                        <p>
                        In an era defined by environmental awareness and climate change, sustainable development has emerged as a cornerstone of responsible urban planning. According to the World Green Building Council, buildings account for 39% of global carbon emissions, highlighting the urgent need for sustainable building practices.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dez-post-media dez-img-effect zoom-slow">
                      {" "}
                      <Link to="/the-evolution-of-architecture" target="_blank">
                        <img src="images/blog/latest-blog/pic3.jpg" alt="" />
                      </Link>{" "}
                    </div>
                    <div className="dez-post-info p-t10">
                      <div className="dez-post-title ">
                        <h5 className="post-title">
                          <Link to="/the-evolution-of-architecture" target="_blank">
                          The Evolution of Architecture: Modern Innovations in Design
                          </Link>
                        </h5>
                      </div>
                      <div className="dez-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <i className="fa fa-calendar" />
                            10 Aug
                            <span> 2016</span>{" "}
                          </li>
                          <li className="post-author">
                            <i className="fa fa-user" />
                            By <Link to="javascript:void(0);">Admin</Link>{" "}
                          </li>
                          <li className="post-comment">
                            <i className="fa fa-comments" />{" "}
                            <Link to="javascript:void(0);">0 comment</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dez-post-text">
                        <p>
                        Architecture is a reflection of society's evolution, shaped by cultural, technological, and environmental factors. From ancient civilizations to modern metropolises, the evolution of architecture has been marked by innovation and adaptation to changing needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="scroltop fa fa-arrow-up style5"
          style={{ background: "#9a2331", color: "#fff" }}
        />
      </div>
    </div>
  );
};

export default Home;
