import React from 'react'

const Blog2 = () => {
  document.title = "Sustainable Development: Building for a Greener Tomorrow"

    const paragraphs = [
        `In an era defined by environmental awareness and climate change, sustainable development has emerged as a cornerstone of responsible urban planning. According to the World Green Building Council, buildings account for 39% of global carbon emissions, highlighting the urgent need for sustainable building practices.`,
`One key aspect of sustainable development is energy efficiency. Green building standards such as LEED (Leadership in Energy and Environmental Design) and BREEAM (Building Research Establishment Environmental Assessment Method) promote energy-efficient design principles, reducing carbon emissions and operational costs while enhancing occupant comfort.`,
`Moreover, sustainable development encompasses the use of eco-friendly materials and construction techniques that minimize environmental impact. The global market for green construction materials is projected to reach $470 billion by 2027, driven by increasing awareness of environmental issues and regulatory requirements.`,
`Furthermore, incorporating renewable energy sources such as solar panels and wind turbines into building design reduces reliance on fossil fuels and promotes energy independence. The renewable energy sector is expected to attract $2.6 trillion in investment by 2030, with solar and wind power leading the way.`,
`Additionally, sustainable development extends beyond the built environment to include considerations for water conservation, waste management, and biodiversity preservation. Green building initiatives can reduce water consumption by up to 40% and divert up to 80% of construction waste from landfills, contributing to a more sustainable future.`,
`In conclusion, sustainable development is not just a trend but a necessity in addressing the urgent challenges of climate change and resource scarcity. By embracing sustainable practices, developers can create healthier, more resilient communities that thrive in harmony with nature.`,
    ]
  return (
    <div className="blog2">
    <div className='p-5 m-5 w-50'>
      <h1 className='mb-5'>Sustainable Development: Building for a Greener Tomorrow</h1>
    {paragraphs?.map((par) => {
        return <p>{par}</p>
    })}
    </div>
    </div>
  )
}

export default Blog2
