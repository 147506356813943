import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { toast } from "react-toastify";
import { addChannelPartner } from "../api/sendDataApi";

const ChannelPartner = () => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const handleChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    form.setFieldValue("position", selectedValues);
  };

  const form = useFormik({
    initialValues: {
      ChannelPartnerName: "",
      CompanyName: "",
      position: [],
      contact: "",
      GSTIN: "",
      EmailId: "",
      Commisioncharge: "",
      Reference: "",
    },
    validationSchema: Yup.object().shape({
      ChannelPartnerName: Yup.string().required(
        "Channel partner name is required"
      ),
      CompanyName: Yup.string().required("Company name is required"),
      position: Yup.array().min(
        1,
        "At least one type of property detail is required"
      ),
      contact: Yup.string()
        .required("Contact number is required")
        .matches(/^\d{10}$/, "Contact number must be exactly 10 digits"),
      GSTIN: Yup.string().matches(
        /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/,
        "GSTIN should be in correct format."
      ),
      EmailId: Yup.string()
        .required("Email address is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address format"
        ),
        Commisioncharge: Yup.number()
        .max(100, "Commision charge must be between 0 to 100")
        .required("Commision charge is required"),
      Reference: Yup.string().required("Reference is required"),
    }),
    onSubmit: async (values) => {
      const data = {
        company_name: values.CompanyName,
        channel_partner_name: values.ChannelPartnerName,
        type_of_properties_dealt: selectedOptions?.map(
          (option) => option.value
        ),
        mobile_no: parseInt(values.contact),
        GSTIN: values.GSTIN || null,
        email_id: values.EmailId,
        commission_charge: parseInt(values.Commisioncharge),
        reference: values.Reference,
      };
      await addChannelPartner(data)
        .then((resp) => {
          if (resp?.status === "Success") {
            toast.success(resp?.message);
          } else {
            toast.error(resp?.message);
          }
        })
        .catch((err) => toast.error(err?.message))
        .finally(() => {
          setSelectedOptions(null);
        });
      form.resetForm();
    },
  });

  const options = [
    { value: "Residential", label: "Residential" },
    { value: "Commercial", label: "Commercial" },
    { value: "Industrial", label: "Industrial" },
    { value: "Weekend Villa/Plot", label: "Weekend Villa/Plot" },
    { value: "Land", label: "Land" },
  ];

  return (
    <div>
      <div id="loading-area" />
      <div className="page-wraper">
        <div className="page-content">
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
          >
            <div className="container px-5">
              <div className="dez-bnr-inr-entry text-start">
                <h1 className="text-white">Channel Partner</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container px-5">
              <ul className="list-inline text-start">
                <li>
                  <Link to="/" className="text-dark">
                    Home
                  </Link>
                </li>
                <li> Channel Partner</li>
              </ul>
            </div>
          </div>
          <div className="section-full content-inner bg-white  p-0">
            <div className="container ">
              <div className="row">
                <div className="page-content">
                  <div className="bg-white">
                    <section className="section-full content-inner-1">
                      <div className="container  text-start">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 ">
                            <div className="p-a30 bg-gray rounded clearfix">
                              <form onSubmit={form.handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Channel Partner Name</label>
                                      <div className="input-group">
                                        <input
                                          name="ChannelPartnerName"
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Channel Partner Name"
                                          value={
                                            form.values.ChannelPartnerName || ""
                                          }
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.ChannelPartnerName && (
                                        <span className="error text-danger">
                                          {form.errors.ChannelPartnerName}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Company Name</label>
                                      <div className="input-group">
                                        <input
                                          name="CompanyName"
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Company Name"
                                          value={form.values.CompanyName || ""}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.CompanyName && (
                                        <span className="error text-danger">
                                          {form.errors.CompanyName}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <label>
                                      Select Type Of Properties Details
                                    </label>
                                    <div className="m-b20">
                                      <div className="input-group text-start dropdownReact">
                                        <Select
                                          className="w-100"
                                          name="position"
                                          options={options}
                                          onChange={handleChange}
                                          onBlur={form.handleBlur}
                                          value={selectedOptions}
                                          isSearchable={false}
                                          isMulti={true}
                                        />
                                      </div>
                                      {form.errors.position && (
                                        <span className="error text-danger">
                                          {form.errors.position}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Contact Number</label>
                                      <div className="input-group">
                                        <input
                                          name="contact"
                                          type="number"
                                          min={1}
                                          className="form-control"
                                          placeholder="Enter Contact no."
                                          value={form.values.contact || ""}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.contact && (
                                        <span className="error text-danger">
                                          {form.errors.contact}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>GSTIN</label>
                                      <div className="input-group">
                                        <input
                                          name="GSTIN"
                                          type="text"
                                          className="form-control gst"
                                          placeholder="GSTIN"
                                          value={form.values.GSTIN || ""}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.GSTIN && (
                                        <span className="error text-danger">
                                          {form.errors.GSTIN}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Email Address</label>
                                      <div className="input-group">
                                        <input
                                          name="EmailId"
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Email Address"
                                          value={form.values.EmailId || ""}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.EmailId && (
                                        <span className="error text-danger">
                                          {form.errors.EmailId}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Commision charge</label>
                                      <div className="input-group">
                                        <input
                                          name="Commisioncharge"
                                          type="number"
                                          min={1}
                                          className="form-control"
                                          placeholder="Enter Commision charge"
                                          value={
                                            form.values.Commisioncharge || ""
                                          }
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.Commisioncharge && (
                                        <span className="error text-danger">
                                          {form.errors.Commisioncharge}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Reference</label>
                                      <div className="input-group">
                                        <input
                                          name="Reference"
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Reference"
                                          value={form.values.Reference || ""}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.Reference && (
                                        <span className="error text-danger">
                                          {form.errors.Reference}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 d-flex justify-content-end ">
                                    <button
                                      name="submit"
                                      type="submit"
                                      value="Submit"
                                      className="site-button"
                                    >
                                      {" "}
                                      <span>Submit</span>{" "}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content END*/}
        {/* Footer */}
      </div>
    </div>
  );
};

export default ChannelPartner;
