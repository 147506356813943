import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="site-footer">
        <div className="footer-top">
          <div className="container px-5">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-4">
                <div className="widget widget_about text-start">
                  <div className="logo-footer">
                    <Link to="https://aranathgroup.com/" className="logo-light">
                      <img
                        src="images/logo-white.png"
                        height={60}
                        width={60}
                        alt=""
                      />{" "}
                    </Link>
                  </div>
                  <p>
                    Established in 2022, Aranath Group is a leading real estate
                    developer in Ahmedabad and Surat. With over 1.2 million
                    square feet of prime real estate developed, our commitment
                    to excellence shines through in every project.
                  </p>
                  <ul className="dez-social-icon dez-border">
                    <li>
                      {" "}
                      <Link to="https://www.facebook.com/aranathgroup?mibextid=LQQJ4d" target="_blank">
                        <i className="fab fa-facebook-f" />{" "}
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.linkedin.com/company/aranath-group/" target="_blank">
                        <i className="fab fa-linkedin-in" />{" "}
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.instagram.com/aranathgroup/" target="_blank">
                        <i className="fab fa-instagram" />{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-4">
                <div className="widget recent-posts-entry text-start">
                  <h4 className="m-b15 text-uppercase">Recent Post</h4>
                  <div className="dez-separator-outer m-b10">
                    <div className="dez-separator bg-white style-skew" />
                  </div>
                  <div className="widget-post-bx">
                    <div className="widget-post clearfix">
                      <div className="dez-post-media">
                        {" "}
                        <img
                          src="images/blog/latest-blog/pic3.jpg"
                          alt=""
                          width={200}
                          height={143}
                        />{" "}
                      </div>
                      <div className="dez-post-info">
                        <div className="dez-post-header">
                          <h6 className="post-title text-uppercase">
                            {" "}
                            <Link
                              to="/the-evolution-of-architecture"
                              target="_blank"
                              className="text-white"
                            >
                              The Evolution of Architecture
                            </Link>
                          </h6>
                        </div>
                        <div className="dez-post-meta">
                          <ul>
                            <li className="post-author">By Admin</li>
                            <li className="post-comment">
                              <i className="fa fa-comments" />0
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="widget-post clearfix">
                      <div className="dez-post-media">
                        {" "}
                        <img
                          src="images/blog/latest-blog/pic2.jpg"
                          alt=""
                          width={200}
                          height={160}
                        />{" "}
                      </div>
                      <div className="dez-post-info">
                        <div className="dez-post-header">
                          <h6 className="post-title text-uppercase">
                            {" "}
                            <Link
                              to="/sustainable-development"
                              target="_blank"
                              className="text-white"
                            >
                              Sustainable Development
                            </Link>
                          </h6>
                        </div>
                        <div className="dez-post-meta">
                          <ul>
                            <li className="post-author">By Admin</li>
                            <li className="post-comment">
                              <i className="fa fa-comments" /> 0
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="widget-post clearfix">
                      <div className="dez-post-media">
                        {" "}
                        <img
                          src="images/blog/latest-blog/pic1.jpg"
                          alt=""
                          width={200}
                          height={160}
                        />{" "}
                      </div>
                      <div className="dez-post-info">
                        <div className="dez-post-header">
                          <h6 className="post-title  text-uppercase">
                            {" "}
                            <Link
                              to="/trends-in-urban-living"
                              target="_blank"
                              className="text-white"
                            >
                              Trends in Urban Living
                            </Link>
                          </h6>
                        </div>
                        <div className="dez-post-meta">
                          <ul>
                            <li className="post-author">By Admin</li>
                            <li className="post-comment">
                              <i className="fa fa-comments" /> 0
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-4">
                <div className="widget widget_services text-start">
                  <h4 className="m-b15 text-uppercase">Our services</h4>
                  <div className="dez-separator-outer m-b10">
                    <div className="dez-separator bg-white style-skew" />
                  </div>
                  <ul className="text-start">
                    <li>
                      <Link to="#">&nbsp;Real Estate Development </Link>
                    </li>
                    <li>
                      <Link to="#">&nbsp;Joint Development Projects </Link>
                    </li>
                    <li>
                      <Link to="#">&nbsp;Turnkey Construction </Link>
                    </li>
                    <li>
                      <Link to="#">
                        &nbsp;RCC and Brick Masonry Work with &nbsp;material{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">&nbsp;Redevelopment </Link>
                    </li>
                    <li>
                      <Link to="#">&nbsp;Investment Consulting </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-4">
                <div className="widget widget_getintuch text-start">
                  <h4 className="m-b15 text-uppercase">Contact us</h4>
                  <div className="dez-separator-outer m-b10">
                    <div className="dez-separator bg-white style-skew" />
                  </div>
                  <ul className="text-start">
                    <li>
                      <i className="fas fa-map-marker-alt" />
                      <strong>address</strong> FF 22 National Chamber, Nr City
                      Gold, Ashram Rd, Ahmedabad, Gujarat 380058{" "}
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <strong>phone</strong>+91-8141487740
                    </li>
                    <li>
                      <i className="fa fa-envelope" />
                      <strong>email</strong>info@aranathgroup.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom footer-line text-start">
          <div className="container px-5">
            <div className="row text-white">
              <div className="col-lg-4 col-md-4 text-white text-left">
                <span>© Copyright 2024</span>
              </div>
              <div className="col-lg-4 col-md-4 text-white text-center">
                <span>
                  {" "}
                  Design With{" "}
                  <i
                    className="fa fa-heart heart"
                    style={{ color: "#9a2331" }}
                  />{" "}
                  By Shiv Infotech{" "}
                </span>
              </div>
              <div className="col-lg-4 col-md-4 text-white text-right">
                <Link to="/about"> About Us </Link>
                <Link to="/contact"> Contact Us </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button className="absolute scroltop fa fa-arrow-up" />
    </>
  );
};

export default Footer;
