import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleSubMenu = () => {
    let drop = document.getElementById('drop')
    drop.classList.toggle('open')
  }

  return (
    <header className="site-header header mo-left header-style-1">
      <div className="top-bar no-skew">
        <div className="container px-5">
          <div className=" d-flex bar align-items-center justify-content-between">
            <div className="dez-topbar-left">
              <ul className="social-bx list-inline  pull-left">
                <li className="m-r10">
                  <i className="fa fa-phone" />
                  +91-8141487740
                </li>
                <li>
                  <i className="fa fa-envelope" />
                  info@aranathgroup.com
                </li>
              </ul>
            </div>
            <div className="dez-topbar-right">
              <ul className="social-bx list-inline pull-right">
                <li>
                  <Link target="_blank" to="https://www.facebook.com/aranathgroup?mibextid=LQQJ4d">
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.linkedin.com/company/aranath-group/">
                    <i className="fab fa-linkedin-in" />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.instagram.com/aranathgroup/">
                    <i className="fab fa-instagram" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <div className="main-bar">
          <div className="container px-5">
            <div className="logo-header mostion dark">
              <Link to="https://aranathgroup.com/" onClick={() => window.location.href = 'https://aranathgroup.com/'} className="logo-dark">
                <img src="images/logo.png" width={50} height={50} alt="" />
              </Link>
            </div>
            <button
              className={
                mobileMenu === true
                  ? `navbar-toggler collapsed navicon justify-content-end open`
                  : `navbar-toggler collapsed navicon justify-content-end`
              }
              type="button"
              onClick={() => toggleMobileMenu()}
            >
              <span />
              <span />
              <span />
            </button>

            <div
              className={
                mobileMenu === true
                  ? `header-nav navbar-collapse collapse justify-content-end show`
                  : `header-nav navbar-collapse collapse justify-content-end`
              }
              id="navbarNavDropdown"
            >
              <div className="logo-header mostion">
                <Link to="/" onClick={() => window.location.href = "/"} className="logo-light">
                  <img
                    src="images/logo-white.png"
                    width={50}
                    height={89}
                    alt=""
                  />
                </Link>
              </div>
              <ul className="nav navbar-nav">
                <li className="has-mega-menu homedemo">
                  <Link to="/" onClick={() => window.location.href = '/'}>Home</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>           
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li className="has-mega-menu ">
                  <Link to="/career">Career</Link>
                </li>
                {/* <li className="has-mega-menu ">
                  <Link to="/partnerwithus">Partner with us</Link>
                </li> */}
                <li className="drop" id="drop" onClick={() => handleSubMenu()}>
                  <Link to="/">
                  Partner with us <i className="fa fa-chevron-down "></i>
                  </Link>
                  <ul className="sub-menu">
                  

                    <li>
                      <Link to="/channelpartner">Channel Partner</Link>
                    </li>
                    <li>
                      <Link to="/redevelopment">Redevelopment</Link>
                    </li>
                    <li>
                      <Link to="">Vendor register</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;