import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  addRedevelopmentInquiry,
  fileUploads,
  listOfCity,
} from "../api/sendDataApi";
import BaseDropzone from "./DropZone";
import { Label } from "reactstrap";

const ChannelPartner = () => {
  const FileRef = useRef()
  const [showUnitsInput, setShowUnitsInput] = useState(false);
  const [showCommercialUnitsInput, setShowCommercialUnitsInput] =
    useState(false);
  const [cityData, setCityData] = useState([]);

  const fetchData = () => {
    listOfCity({})
      .then((res) => {
        setCityData(
          res?.data?.map((item) => ({
            value: item?.id,
            label: item?.city_name,
          }))
        );
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const form = useFormik({
    initialValues: {
      NameofSociety: "",
      StageofRedevelopment: "",
      contact: "",
      MembersAgree: "",
      EmailId: "",
      ContactPerson: "",
      ResidentialUnits: "",
      CommercialUnits: "",
      GoogleLocationPin: "",
      city: "",
      Address: "",
      position: "",
      images: [],
    },

    onSubmit: async (values) => {
      if (values.images.length > 0) {
        const formData = new FormData();
        values.images.forEach((image) => {
          formData.append("files", image);
        });

        try {
          // Upload all images first
          const imageResponse = await fileUploads(formData);
          const imageUrls = imageResponse?.data?.map((image) => image);
          const data = {
            name_of_Society: values.NameofSociety,
            address: values.Address,
            google_location_pin: values.GoogleLocationPin,
            city_id: values.city,
            society_type: values.position,
            no_of_ResidentialUnits: values.ResidentialUnits,
            no_of_CommercialUnits: values.CommercialUnits || null,
            stage_of_redevelopment: values.StageofRedevelopment,
            members_agree: values.MembersAgree,
            contact_person: values.ContactPerson,
            mobile_no: values.contact,
            site: "Architecture",
            images: imageUrls,
          };

          const response = await addRedevelopmentInquiry(data);
          if (response?.status === "Success") {
            toast.success(response?.message);
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error?.message);
        } finally {
          form.resetForm();
          form.setFieldValue("images", []);
          FileRef.current.value = ""
        }
      } else {
        const data = {
          name_of_Society: values.NameofSociety,
          address: values.Address,
          google_location_pin: values.GoogleLocationPin,
          city_id: values.city,
          society_type: values.position,
          no_of_ResidentialUnits: values.ResidentialUnits,
          no_of_CommercialUnits: values.CommercialUnits || null,
          stage_of_redevelopment: values.StageofRedevelopment,
          members_agree: values.MembersAgree,
          contact_person: values.ContactPerson,
          mobile_no: values.contact,
          images: [],
        };

        try {
          const response = await addRedevelopmentInquiry(data);
          if (response?.status === "Success") {
            toast.success(response?.message);
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error?.message);
        } finally {
          form.resetForm();
          form.setFieldValue("images", []);
          form.setFieldValue("city", "");
        }
      }
    },

    validate: (values) => {
      const errors = {};
      const contactPattern = /^\d{10}$/;
      // Validation for Name of Society
      if (!values.NameofSociety) {
        errors.NameofSociety = "Name of society is required";
      }

      if (!values.ContactPerson) {
        errors.ContactPerson = "Contact person is required";
      }

      if (!values.position) {
        errors.position = "Society type is required";
      }

      if (!values.ResidentialUnits && values.position) {
        errors.ResidentialUnits = "Residential units is required";
      }

      if (
        values.position === "Residential + Commercial" &&
        !values.CommercialUnits
      ) {
        errors.CommercialUnits = "Commercial units is required";
      }

      if (!values.StageofRedevelopment) {
        errors.StageofRedevelopment = "Stage of redevelopment is required";
      }

      if (!values.contact) {
        errors.contact = "Contact number is required";
      } else if (!contactPattern.test(values.contact)) {
        errors.contact = "Contact number must be exactly 10 digits";
      }

      if (!values.MembersAgree) {
        errors.MembersAgree = "Members agree is required";
      }
      if (!values.GoogleLocationPin) {
        errors.GoogleLocationPin = "Google location pin is required";
      }
      if (!values.city) {
        errors.city = "City is required";
      }
      if (!values.Address) {
        errors.Address = "Address is required";
      }

      return errors;
    },
  });

  const options = [
    { value: "Residential", label: "Residential" },
    { value: "Residential + Commercial", label: "Residential + Commercial" },
  ];
  const options1 = [
    { value: "Under discussion", label: "Under discussion" },
    { value: "MOU Done", label: "MOU Done" },
    { value: "Builder offer received", label: "Builder offer received" },
  ];

  const handlePositionChange = (selectedOption) => {
    form.setFieldValue("position", selectedOption.value);
    if (selectedOption.value === "Residential") {
      setShowUnitsInput(true);
      setShowCommercialUnitsInput(false);
      form.setFieldValue("CommercialUnits", "");
    } else if (selectedOption.value === "Residential + Commercial") {
      setShowUnitsInput(true);
      setShowCommercialUnitsInput(true);
    }
  };
  const handleChangeStatus = (e) => {
    if (e && e.target && e.target.files) {
      const files = e.target.files;
      const newImages = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const isDuplicate = form?.values?.images?.some(
          (image) => image?.name === file?.name
        );
        if (!isDuplicate) {
          if (file.size <= 1024 * 1024) {
            newImages.push(file);
          } else {
            toast.error("File size should be less than 1MB");
          }
        }
      }
      form?.setFieldValue("images", [...form?.values?.images, ...newImages]);
    }
  };

  return (
    <div>
      <div id="loading-area" />
      <div className="page-wraper">
        <div className="page-content">
          <div
            className="dez-bnr-inr overlay-black-middle"
            style={{ backgroundImage: "url(images/background/bg4.jpg)" }}
          >
            <div className="container px-5">
              <div className="dez-bnr-inr-entry text-start">
                <h1 className="text-white">Redevelopment</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container px-5">
              <ul className="list-inline text-start">
                <li>
                  <Link to="/" className="text-dark">
                    Home
                  </Link>
                </li>
                <li> Redevelopment</li>
              </ul>
            </div>
          </div>
          <div className="section-full content-inner bg-white  p-0">
            <div className="container col-lg-10">
              <div className="row">
                <div className="page-content">
                  <div className="bg-white">
                    <section className="section-full content-inner-1">
                      <div className="container col-lg-10 text-start">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 ">
                            <div className="p-a30 bg-gray rounded clearfix">
                              <form onSubmit={form.handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Name of Society</label>
                                      <div className="input-group">
                                        <input
                                          name="NameofSociety"
                                          type="text"
                                          className="form-control"
                                          placeholder="Name of Society"
                                          value={form.values.NameofSociety}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.NameofSociety && (
                                        <span className="error text-danger">
                                          {form.errors.NameofSociety}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Contact Person</label>
                                      <div className="input-group">
                                        <input
                                          name="ContactPerson"
                                          type="text"
                                          min={1}
                                          className="form-control"
                                          placeholder="Contact Person"
                                          value={form.values.ContactPerson}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.ContactPerson && (
                                        <span className="error text-danger">
                                          {form.errors.ContactPerson}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Selcete Society Type</label>
                                      <div className="input-group text-start dropdownReact">
                                        <Select
                                          className="w-100"
                                          name="position"
                                          options={options}
                                          onChange={handlePositionChange}
                                          value={
                                            options.find(
                                              (option) =>
                                                option.value ===
                                                form.values.position
                                            ) || ""
                                          }
                                          isSearchable={false}
                                        />
                                      </div>
                                      {form.errors.position && (
                                        <span className="error text-danger">
                                          {form.errors.position}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Residential Units</label>
                                      <div className="input-group">
                                        <input
                                          name="ResidentialUnits"
                                          type="number"
                                          min={1}
                                          className="form-control"
                                          value={form.values.ResidentialUnits}
                                          placeholder="No. of Residential Units"
                                          onChange={form.handleChange}
                                          disabled={!showUnitsInput}
                                        />
                                      </div>
                                      {form.errors.ResidentialUnits && (
                                        <span className="error text-danger">
                                          {form.errors.ResidentialUnits}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Commercial Units</label>
                                      <div className="input-group">
                                        <input
                                          name="CommercialUnits"
                                          type="number"
                                          min={1}
                                          className="form-control "
                                          value={form.values.CommercialUnits}
                                          placeholder="No. of Commercial Units"
                                          onChange={form.handleChange}
                                          disabled={!showCommercialUnitsInput}
                                        />
                                      </div>
                                      {form.errors.CommercialUnits && (
                                        <span className="error text-danger">
                                          {form.errors.CommercialUnits}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>
                                        Select Stage of Redevelopment
                                      </label>
                                      <div className="input-group text-start dropdownReact">
                                        <Select
                                          className="w-100"
                                          name="StageofRedevelopment"
                                          options={options1}
                                          onChange={(selectedOption) =>
                                            form.setFieldValue(
                                              "StageofRedevelopment",
                                              selectedOption.value
                                            )
                                          }
                                          value={
                                            options1.find(
                                              (option) =>
                                                option.value ===
                                                form.values.StageofRedevelopment
                                            ) || ""
                                          }
                                          isSearchable={false}
                                        />
                                      </div>
                                      {form.errors.StageofRedevelopment && (
                                        <span className="error text-danger">
                                          {form.errors.StageofRedevelopment}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Contact No</label>
                                      <div className="input-group">
                                        <input
                                          name="contact"
                                          type="number"
                                          min={1}
                                          className="form-control"
                                          placeholder="Contact no."
                                          value={form.values.contact}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.contact && (
                                        <span className="error text-danger">
                                          {form.errors.contact}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Members Agree</label>
                                      <div className="input-group">
                                        <input
                                          name="MembersAgree"
                                          type="number"
                                          min={1}
                                          className="form-control"
                                          placeholder="Members Agree"
                                          value={form.values.MembersAgree}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.MembersAgree && (
                                        <span className="error text-danger">
                                          {form.errors.MembersAgree}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>Google LocationPin</label>
                                      <div className="input-group">
                                        <input
                                          name="GoogleLocationPin"
                                          type="text"
                                          className="form-control"
                                          placeholder="Google Location Pin"
                                          value={form.values.GoogleLocationPin}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.GoogleLocationPin && (
                                        <span className="error text-danger">
                                          {form.errors.GoogleLocationPin}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="m-b20">
                                      <label>City</label>
                                      <div className="input-group">
                                        <Select
                                          className="w-100"
                                          name="city"
                                          options={cityData}
                                          onChange={(selectedOption) =>
                                            form.setFieldValue(
                                              "city",
                                              selectedOption.value
                                            )
                                          }
                                          value={
                                            cityData?.find(
                                              (option) =>
                                                option.value ===
                                                form.values.city
                                            ) || ""
                                          }
                                          isSearchable={true}
                                        />
                                      </div>
                                      {form.errors.city && (
                                        <span className="error text-danger">
                                          {form.errors.city}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="m-b20">
                                      <label>Address</label>
                                      <div className="input-group">
                                        <input
                                          name="Address"
                                          type="text"
                                          className="form-control"
                                          placeholder="Address"
                                          value={form.values.Address || ""}
                                          onChange={form.handleChange}
                                        />
                                      </div>
                                      {form.errors.Address && (
                                        <span className="error text-danger">
                                          {form.errors.Address}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-12 mb-2 ">
                                    <Label className="d-block">
                                      Upload Image
                                      <i className="fas fa-question-circle ml-1 tooltip-container">
                                        <div class="tooltip-content">
                                          <ul>
                                            <li>Society Plan</li>
                                            <li>MoU</li>
                                            <li>Other offer received</li>
                                            <li>Land documents</li>
                                          </ul>
                                        </div>
                                      </i>
                                    </Label>

                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={handleChangeStatus}
                                      ref={FileRef}
                                      multiple
                                    />

                                    <div className="mt-2 row">
                                      {form?.values?.images &&
                                        form?.values?.images?.map(
                                          (image, index) => (
                                            <div
                                              key={index}
                                              className="col-lg-3 col-md-4 col-sm-6 mb-3"
                                            >
                                              <div className="card preview-image">
                                                <img
                                                  src={URL.createObjectURL(
                                                    image
                                                  )}
                                                  alt={`Preview ${index}`}
                                                  className="card-img-top img-size"
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-lg-12 d-flex justify-content-end">
                                    <button
                                      name="submit"
                                      type="submit"
                                      value="Submit"
                                      className="site-button"
                                    >
                                      {" "}
                                      <span>Submit</span>{" "}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* contact area  END */}
        </div>
        {/* Content END*/}
        {/* Footer */}
      </div>
    </div>
  );
};

export default ChannelPartner;
